<template>
  <b-button
    type="submit"
    variant="primary"
    class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1 btn-sm-block"
    :disabled="btnDisabled"
  >
    {{ btnText }}
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    btnDisabled: {
      type: Boolean,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
  },
}
</script>
